import React, { useEffect, useState, useContext } from "react";
import { strings } from "./../../services/Localization";
import { checkLogin } from "./../../services/Login";
declare const constants;
import { useNavigate, useParams } from 'react-router-dom';
import { faTrashAlt , faAdd,  faRefresh } from '@fortawesome/pro-regular-svg-icons'
import { IUser } from "src/dassTypes";
import {  dialog, dialogDescription } from '../../components/Common';
import { TAB_ADD_TAG, TAB_EDIT_TAG } from '../../datatypes/tabsconstants'
import AppContext from '../../context/AppContext'
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { ActionType, BulkActionType, ColumnType, DataTableOption,  } from '../../components/Common/DataTable/DataTypes';
import { getAppBase, getLabelBasedOnServerType } from '../../utils/consts';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "./../../utils/Toaster";
import { getVisibleActions, actionIcon, elipsis } from "./../../utils/filters";
import{ ID_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";
import PageContent from "../PageContent";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";

interface ITagsStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    editUserId: string;
    pageTitle: string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
    isRowDeleted?:boolean;
}

interface IRowType {

   tagid: string;
   title: string;
   commemt: string;
   color: string;

}


const Tags: React.FC<{}> = () => {

    const AppContextObj = useContext(AppContext);
    const navigate = useNavigate();
    let { id, tabname } = useParams();
    
    const stateInit = {
        loggedUser: AppContextObj.user,
        pageTitle: strings.MY_TAGS, // FIXME 
        showAlertModal: false,
        editUserId: '',
        breadCrumbArr: [getLabelBasedOnServerType(strings.NAV_DEVICE_MANAGEMENT, '', ''), {label: strings.NAV_TAG_ITEM, url:''}], // FIXME
        refresh:false,
        isRowDeleted:false
    };

    const [tagState, setTagState] = useState<ITagsStates>(stateInit)

    useEffect(() => {
        
        if(checkLogin(AppContextObj.user)) {
            setTagState(prevState => { return {...prevState, loggedUser: AppContextObj.user }})
        }

    },[])

     const detailPageNav = ( navigate, tab, tagid, row) => {
        
        navigate(`${getAppBase()}/tags/${tagid}/${tab}`, {state: {row: row, prevPageUrl: `${getAppBase()}/tags`}})
    }

    const detailPageAddNav = ( navigate, tab) => {
        
        navigate(`${getAppBase()}/tags/${tab}`, {state: {prevPageUrl: `${getAppBase()}/tags`}})
    }

   const getActions = () => {

        let actions: ActionType<IRowType>[] = [
        {
            type: "action",
            text: strings.TAG_DELETE,
            visible: () => { return AppContextObj.user && AppContextObj.user.can_delete_tags && !AppContextObj.user._readonly },
            render: (row, title) => actionIcon(row.tagid, title, faTrashAlt.iconName),
            action: (group) => deleteTag(group)
        }]

        let bulkActions:BulkActionType<IRowType>[] = [
        
            {
                type: "action",
                text: strings.TAGS_DELETE,
                visible: () => { return AppContextObj.user && AppContextObj.user.can_delete_tags && !AppContextObj.user._readonly },
                render: (row, title) => actionIcon(row.tagid, title, faTrashAlt.iconName),
                action: (selectedIds) => deleteTagInBulk(selectedIds)
            }
        ]

        return {
            actions: actions,
            bulkActions:bulkActions
        }
        
    }


    const deleteTagInBulk =  async (selectedRows: IRowType[]) => {
        const tagIds = selectedRows.map((row) => {
            return row['tagid'];
        })
        if(tagIds && tagIds.length > 0) {
            const confirmDialogeSettings = {
                title:  `Do you want to delete the ${tagIds.length} selected tag(s)`,
                description: await dialogDescription(tagIds) + "",
                actionLabel: 'Delete',
            };

            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(tagIds.map((tagid) => {
                        return GenericDassQuery("/rest/tags/" + tagid, { method: "DELETE" });
                    })).then((values) => {
                        refreshTable(true);
                        toast.success("Bulk Tags deleted successfully");
                    });
                } catch (e) {
                    toast.error(e.message);
                }
            }
        }
    }


   const deleteTag =  async (tag) => {
        const confirmDialogeSettings = {
            title:  "Do you want to delete this tag",
            description: tag.tagid,
            actionLabel: 'Delete',
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {

                Promise.resolve(GenericDassQuery("/rest/tags/" + tag.tagid, { method: "DELETE" })).then((values) => {

                    refreshTable(true);
                    toast.success("Tag successfully deleted");

                });
               
            } catch (e) {
                toast.error(e.message);
            }
        }
    }

   const initDataTable = () => {
		
        const {actions, bulkActions} = getActions();
        
		const columns: ColumnType[] = [
            {
                key: "tagid",
                type: "text",
                title: strings.TAG_ID,
                filterable: true,
                filterField: 'search_id',
                filterType: 'text',
                sortKey: "sort_by_tagid",
                sortable: true,
                inputValidation: ID_INPUT_VALIDATION,
                filterParams: {
                    mapper: (x) => x || undefined
                },

                detailLink: true,
                detailPageNav: (row: IRowType) =>  detailPageNav(navigate, TAB_EDIT_TAG, row.tagid, row ),
                dataAlign:'left',
                extraClass: 'ow-datatable-overflow-ellipsis',
                newCellWidth: isMobile ? '250px' : "30%"
            }, 
            {
                key: "description",
                title: "Description",
                type: "text",
                inputValidation: "",
                filterable: true,
                filterField: 'search_description',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                render: (row) => elipsis(row.description, 50),
                render_tooltip: (row) => row.description,
            
                newCellWidth: '60%'
            },
            {
                key: "color",
                title: "Color",
                type: "text",
                inputValidation: "",
                render: (row: IRowType) => {
                    let res: JSX.Element[] = [];
                    if (row.color) {
                        res.push(<FontAwesomeIcon key={"tag" + id} icon={faTag} color={row.color} size="xs"></FontAwesomeIcon>)
                    }
                    return (<div>{res}</div>)
                },
                dataAlign: 'center',
                newCellWidth: '5%'
            }
        ];



        const options:DataTableOption<IRowType> = {
            
            url:'/uiapi/rest/tags',
            query_param:{all:true, get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'progress'},
            serial_number:false,
            id_field:'tagid',
            oboe_path:'pages.*',
            available_key: 'tagid',
            defaultSortField: 'sort_by_tagid',
            defaultSortOrder: 'asc',
            emptyDataMsg: strings.NO_TAG_AVAILABLE,
            modal:false, ///pass true to render single select radio buttons in palce of checkbox
            columns,
            actions: actions,
            bulkActions: getVisibleActions(bulkActions)

        }

        return options;
	}
    
 

    const refreshTable = (isRowDeleted=false) => {

        setTagState(prevState => {
            return {...prevState, refresh:!prevState.refresh,isRowDeleted:isRowDeleted}
        })
        
    }
    
   const getPageButtons = () => {
        
        const pageButtons: PageButtonType[] = [
            {
                title: "Tag",
                action: () => detailPageAddNav(navigate, TAB_ADD_TAG ),
                type: 'button_with_icon',
                icon: faAdd,
                visible: () => { return AppContextObj.user && AppContextObj.user.can_create_tags && !AppContextObj.user._readonly }
            },
            {
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh
            }
        ];

        return pageButtons;
    }


    return (
        <PageContent
            name="my-tags" 
            id={id} 
            tabname={tabname} 
            actions={getActions()} 
            breadCrumbArr={tagState.breadCrumbArr} 
            pageButtons={getPageButtons()} 
            countLabel={`Tags`} 
            dataTableOption={initDataTable()} 
            isRowDeleted={tagState.isRowDeleted}
            refresh={tagState.refresh}>
        </PageContent>
    );

}


export default Tags;