import React, { /*useEffect, useState,*/ useContext} from "react";
import { OverlayTrigger, Tooltip  } from "react-bootstrap";

import { strings } from "../../../services/Localization";

import NoPositionOnMap from "./../../../assets/map-marker-slash.svg";
import MapContext from '../../../context/MapContext';


import {  useNavigate  } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBroadcastTower } from "@fortawesome/pro-regular-svg-icons";
import { getLedIcon } from "../../../pages/Devices/DmpDevices";
import { NoDataMsg } from "../../Common/DataTable";
// import { formatText } from "../../../schemaengine/client/SchemaTextParser";

interface INetworkMapTableProps {
    tab:any;
    selectedStatus: string[];
}

const DeviceTable:React.FC<INetworkMapTableProps> = (props) => {
    const MapContextObj = useContext(MapContext);
    
    const navigate = useNavigate();
    
    let FilterdTableData = MapContextObj.networkState.TableData;
    if(props.tab === 'gateways') {
        let selectedStatus = props.selectedStatus;

        if(selectedStatus.length > 0) {
            FilterdTableData = FilterdTableData.filter(data => {
                return selectedStatus.includes(data.status);
            })

        }
    }

    const FlyToTheMarker = (data, type) => {

        MapContextObj.FlyToTheMarker( data, type, navigate )

    }

    const emptyMessage = (props.tab === 'gateways') ?  strings.NO_GATEWAY_AVAILABLE : strings.NO_DEVICE_AVAILABLE;
 
    return (
      
            <div className="panel panel-default" id="map-device-list-panel">
                <div className="panel-body panel_body_newstyle">
                <table className="table table-hover table_newstyle">
                    <tbody>

                        {FilterdTableData.length > 0 && FilterdTableData.map((data, index) => {

                            const type = (data.hasOwnProperty("deveui")) ? "Device" : (data.hasOwnProperty("deviceUuid")) ? "DmpDevice" : "Gateway";
                            const id = (data.hasOwnProperty("deveui")) ? data.deveui : data.id;
                            // const DmpdeviceUuid = type === "DmpDevice" ? formatText(`[[fa-ellipsis]]${data.deviceUuid.slice(-6)}`, data.deviceUuid, null, null) : "";

                            return <tr style={{cursor:'pointer'}} className={(!data.longitude || !data.latitude) ? "no-position" : ""}  key={index}>
                                    <td onClick={() => FlyToTheMarker(data, type)}>
                                    <small>
                                        {type === "Gateway" && 
                                            <> 
                                            <FontAwesomeIcon icon={faBroadcastTower} className={` ${data.status === "OK" ? "green_icon" : "red_icon"}`}    id="gateway_icon"/> &nbsp;</>
                                        }
                                        { type === "DmpDevice" &&  <> {getLedIcon(data)} &nbsp;</>}
                                        {type === "Device" && id.match(/.{2}/g).join("-").toUpperCase()}
                                        {type === "DmpDevice" && (<>{data.name || data.deviceId}</>)}
                                        {type === "DmpDevice" && (<span className="font-monospace fa-80"><br/>{data.deviceUuid}</span>)}                                        
                                        {type === "Gateway" && id?.toUpperCase()}
                                        {(!data.latitude || !data.longitude) && 
                                            <>
                                                &nbsp;
                                                <OverlayTrigger
                                                    placement="left"
                                                    overlay={
                                                        <Tooltip id="no_position_tooltip">
                                                            {strings.MAP_NO_POSITION_SET}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <img src={NoPositionOnMap} id="no_position_icon" alt="" />
                                                </OverlayTrigger>
                                            </>
                                        }
                                        {type === "Device" && (<><br/>{data.comment}</>)}
                                        {type === "Gateway" && (<><br/>{data?.name}</>)}
                                    </small>
                                </td>
                            </tr>
                        })}

                        { 
                        FilterdTableData.length === 0 && <NoDataMsg noAddButton={true} title={emptyMessage} />
                        }
                    </tbody>
                </table>
            </div>
            </div> 
    );
    
}

export default DeviceTable;