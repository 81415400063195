import React, { useEffect, useState, useContext } from "react";
import { strings } from "../../services/Localization";
import { checkLogin } from "../../services/Login";
import { useNavigate, useParams } from 'react-router-dom';
import { IUser } from "src/dassTypes";
import {  dialog, dialogDescription } from '../../components/Common';
import {  TAB_ADD_ALARM_DEFINITION, TAB_ALARM_DEFINITION_INFO } from '../../datatypes/tabsconstants'
import AppContext from '../../context/AppContext'
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { ActionType, BulkActionType, ColumnType, DataTableOption  } from '../../components/Common/DataTable/DataTypes';
import { getAppBase, UUIDCOLWIDTH } from '../../utils/consts';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "../../utils/Toaster";
import { getVisibleActions, actionIcon, prioritizedTableColumns } from "../../utils/filters";
import{ ID_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT, DEFAULT_INPUT_VALIDATION }  from "../../components/Common/DataTable/DataTableConsts";
import PageContent from "../PageContent";
import { isMobile } from "react-device-detect";
import { faTrashCan,  faAdd, faRefresh, faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { trustedFormatText } from "../../schemaengine/client/SchemaTextParser";
import { deviceData, emptyDeviceData } from "../../components/Common/DataTable/DataTableState";


interface IAlarmDefinitionState {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    editUserId: string;
    pageTitle: string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
    isRowDeleted?:boolean;
}

interface IRowType {

  alarmDefinitionUuid: string;
  name: string;
  message: string;
  severity: string;
  notificationPolicyUuid: string;

}


const AlarmDefinition: React.FC<{}> = () => {

    const AppContextObj = useContext(AppContext);
    const navigate = useNavigate();
    let { id, tabname } = useParams();
    const stateInit = {
        loggedUser: AppContextObj.user,
        pageTitle: strings.NAV_MANAGE_ALARM_DEFINITIONS,
        showAlertModal: false,
        editUserId: '',
        breadCrumbArr: [{label: strings.NAV_ALERTS, url:''}, {label: strings.NAV_MANAGE_ALARM_DEFINITIONS, url:''}],
        refresh:false,
        isRowDeleted:false
    };

    const [alarmDefinitionState, setAlarmDefinitionState] = useState<IAlarmDefinitionState>(stateInit)


    const notificationPolicyFetcher = async () => {

        if(!deviceData['notificationPolicies']) {
            const notificationPolicyData  = await GenericDassQuery(`/rest/notification-policies`, { method: "GET" });
            deviceData['notificationPolicies'] = notificationPolicyData.data.map(ele => { return { label : ele.name,  value: ele.notificationPolicyUuid }})
        }
        return deviceData['notificationPolicies'];
    }

  

    useEffect(() => {


        emptyDeviceData();

        if(checkLogin(AppContextObj.user)) {
            setAlarmDefinitionState(prevState => { return {...prevState, loggedUser: AppContextObj.user }})
        }

        notificationPolicyFetcher();

    },[AppContextObj.user?.userid, alarmDefinitionState.refresh])

     const detailPageNav = ( navigate, tab, name, row) => {
        
        navigate(`${getAppBase()}/alarmdefinition/${name}/${tab}`, {state: {row: row, prevPageUrl: `${getAppBase()}/alarmdefinition`}})
    }

    const detailPageAddNav = ( navigate, tab) => {
        
        navigate(`${getAppBase()}/alarmdefinition/${tab}`, {state: {prevPageUrl: `${getAppBase()}/alarmdefinition`}})
    }


   const getActions = () => {

        let actions: ActionType[] = [
        {
            type: "action",
            text: strings.DELETE_ALARM_DEFINITIONS,
            visible: () => (AppContextObj?.user?.can_delete_rules && !AppContextObj?.user?._readonly),
            render: (row, title) => actionIcon(row.alarmDefinitionUuid, title, faTrashAlt.iconName),
            action: (group) => deleteRule(group)
        }]


        const bulkActions: BulkActionType<IRowType>[] = [
          {
              type: "header",
              text: "Default Actions"
          },
          {
              type: "action",
              text: strings.DELETE_ALARM_DEFINITIONS,
              render: (row, title) => actionIcon(row.alarmDefinitionUuid, title, faTrashCan.iconName),
              action: (selectedIds) => deleteRuleInBulk(selectedIds),
              visible: () => { 
                  return AppContextObj.user?.account_status === "active" 
                         && !AppContextObj.user?._readonly
                         &&  AppContextObj.user?.can_delete_rules
              } 
          }];

        return {
            actions: actions,
            bulkActions:bulkActions
        }
        
    }


    const deleteRuleInBulk =  async (selectedRows: IRowType[]) => {
        const names = selectedRows.map((row) => {
            return row['name'];
        })
        const alarmDefinitionUuids = selectedRows.map((row) => {
            return row['alarmDefinitionUuid'];
        })
        if(alarmDefinitionUuids && alarmDefinitionUuids.length > 0) {
            const confirmDialogeSettings = {
                title:  strings.BULK_DELETE_CONFIRM_DIALOAG_ALARM_DEFINITION,
                description: await dialogDescription(names) + "",
                actionLabel: strings.ACTION_DELETE,
            };

            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(alarmDefinitionUuids.map((alarmDefinitionUuid) => {
                        return GenericDassQuery("/rest/alarm-definitions/" + alarmDefinitionUuid, { method: "DELETE" });
                    })).then((values) => {
                        refreshTable(true);
                        toast.success(strings.BULK_DELETE_ALARM_DEFINITION_SUCCESS_MESSAGE);
                    });
                } catch (e) {
                    toast.error(e.message);
                }
            }
        }
    }


   const deleteRule =  async (alarmdefinition) => {
        const confirmDialogeSettings = {
            title:  strings.DELETE_CONFIRM_DIALOG_ALARM_DEFINITION,
            description: alarmdefinition.name,
            actionLabel: strings.ACTION_DELETE,
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {

                Promise.resolve(GenericDassQuery("/rest/alarm-definitions/" + alarmdefinition.alarmDefinitionUuid, { method: "DELETE" })).then((values) => {

                    refreshTable(true);
                    toast.success(strings.DELETE_ALARM_DEFINITION_SUCCESS_MESSAGE);

                });
               
            } catch (e) {
                toast.error(e.message);
            }
        }
    }
  

    const renderNotificationPolicies = (notificationPoliciesUuid: string) => {
        let notificationPolicies = "";
        try{
            
                if(deviceData['notificationPolicies'] && deviceData['notificationPolicies'].length > 0){
                    const findIndex = deviceData['notificationPolicies'].findIndex((res) => res.value == notificationPoliciesUuid);
                    if(findIndex != -1){
                        if(!notificationPolicies) {
                            notificationPolicies = notificationPolicies + deviceData['notificationPolicies'][findIndex].label
                        } else {
                            notificationPolicies = notificationPolicies + ',' + deviceData['notificationPolicies'][findIndex].label
                        }
                        
                    } 
                }   
        } catch(e){
            console.log("e",e)
        }        
        return notificationPolicies
    }





   const initDataTable = () => {
        
        const {actions, bulkActions} = getActions();

        const columns: ColumnType[] = [
            {
                key: 'bulk_action_checkbox',
                type: "bulk_action_checkbox",
                title: 'Bulk Action',
                filterable: false,
                cellWidth: 3,
                newCellWidth: "30px",
                customClass: 'sticky left-first',
            },

            {
                key: 'alarmDefinitionUuid',
                type: "text",
                title: strings.TABLE_UUID,
                render: (row: IRowType) => (isMobile ? row.alarmDefinitionUuid : trustedFormatText(`[[fa-ellipsis]]${row.alarmDefinitionUuid.slice(-6)}`, row.alarmDefinitionUuid, null, null)),
                cellWidth: 3,
                newCellWidth: (isMobile ? UUIDCOLWIDTH : '90px'),
                copyLink: true,
                customClass: 'font-monospace fa-80 sticky left-second',
                render_tooltip: (row) => row.alarmDefinitionUuid,
                filterable: true,
                filterField: "search_alarm_definition_uuid",
                sortable: true,
                sortKey: "sort_by_alarm_definition_uuid",
                filterType: "text",
                dataAlign: "center",
                inputValidation: ID_INPUT_VALIDATION
  
            },
            {
                key: "name",
                type: "text",
                title: strings.TABLE_NAME,
                filterable: true,
                filterField: 'search_name',
                filterType: 'text',
                sortKey: "sort_by_name",
                sortable: true,
                inputValidation: ID_INPUT_VALIDATION,
                filterParams: {
                    mapper: (x) => x || undefined
                },

                detailLink: true,
                detailPageNav: (row) =>  detailPageNav(navigate, TAB_ALARM_DEFINITION_INFO, row.alarmDefinitionUuid, row ),
                customClass: 'nowarp',
                extraClass: 'ow-datatable-overflow-ellipsis',
                newCellWidth: isMobile ? '250px' : "350px"
            },
             {
                key: "message",
                title: strings.MESSAGE,
                type: "text",
                filterable: false,
                filterField: "search_description",
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: false,
                sortKey: "sort_by_description",
                filterParams: {},
                cellWidth: 50,
                dataAlign:'left',
                extraClass: 'ow-datatable-overflow-ellipsis',
                render_tooltip: (row) => row.message
            }, 
            {
                key: "severity",
                title: strings.SEVERITY,
                type: "text",
                filterField: "search_severity",
                filterable: true,
                filterType: "select",
                filterWidth: "120px",
                sortable: true,
                sortKey: "sort_by_severity",
                newCellWidth: "120px",
                filterParams: {
                    options: [
                        { label:strings.ALARM_SEVERITY_CRITICAL, value: "critical" },
                        { label:strings.ALARM_SEVERITY_WARNING, value: "warning" },
                        { label:strings.ALARM_SEVERITY_INFO, value: "info" },
                    ], 
                },
            },
            {
                key: "notificationPolicyUuid",
                title: strings.NOTIFICATION_POLICY,
                type: "text",
                filterable: false,
                filterField: "search_notification_policy",
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: false,
                sortKey: "sort_by_notification_policy",
                filterParams: {
                    optionFetcher: notificationPolicyFetcher,
                    data: [],
                    mapper: x => x,
                },
                cellWidth: 50,
                dataAlign:'left',
                customClass: 'nowarp',
                extraClass: 'ow-datatable-overflow-ellipsis',
                newCellWidth: '300px',
                render_tooltip: (row) => row.notificationPolicyUuid,
                render: (row) => renderNotificationPolicies(row.notificationPolicyUuid)
            }
        ];

        columns.push({
            key: 'action_button',
            type: "action_button",
            title: 'Actions',
            filterable: false,
            cellWidth: 3,
            newCellWidth: "140px",
            customClass: 'sticky right',
        });

        if (isMobile) prioritizedTableColumns(['bulk_action_checkbox', 'name'], columns)


        const options:DataTableOption<IRowType> = {
            
            url:'/uiapi/rest/alarm-definitions',
            query_param:{ all:true, get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'progress'},
            serial_number:false,
            id_field:'name',
            oboe_path:'pages.*',
            available_key: 'name',
            modal:false, ///pass true to render single select radio buttons in palce of checkbox
            columns,
            actions: actions,
            defaultSortField: 'sort_by_name',
            defaultSortOrder: 'asc',
            emptyDataMsg: strings.NO_ALARM_DEFINITION_AVAILABLE,
            bulkActions: getVisibleActions(bulkActions)

        }

        return options;
    }
    
 

    const refreshTable = (isRowDeleted=false) => {

        setAlarmDefinitionState(prevState => {
            return {...prevState, refresh:!prevState.refresh,isRowDeleted:isRowDeleted}
        })
        
    }
    
   const getPageButtons = () => {
        
        const pageButtons: PageButtonType[] = [
            {
                title: strings.ADD_ALARM_DEFINITION,
                action: () => detailPageAddNav(navigate, TAB_ADD_ALARM_DEFINITION ),
                type: 'button_with_icon',
                icon: faAdd,
                visible: () => { return AppContextObj?.user && AppContextObj?.user?.can_create_rules && !AppContextObj?.user?._readonly }
            },
            {
              
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh
            }
        ];

        return pageButtons;
    }


    return (
        <PageContent
            name="my-alarm-definations" 
            id={id} 
            tabname={tabname} 
            actions={getActions()} 
            breadCrumbArr={alarmDefinitionState.breadCrumbArr} 
            pageButtons={getPageButtons()} 
            countLabel={strings.NAV_MANAGE_ALARM_DEFINITIONS} 
            dataTableOption={initDataTable()} 
            isRowDeleted={alarmDefinitionState.isRowDeleted}
            refresh={alarmDefinitionState.refresh}>
        </PageContent>
    );

}


export default AlarmDefinition;
